.file-uploads {
  .progress {
    height: 8px;
    margin-top: 4px;
    margin-bottom: 2px;
  }
}

.sidebar > .panel {
  border-radius: 0;
  margin-bottom: 0;
}

.sidebar > .panel > .panel-heading {
  border-radius: 0;
}

.sidebar > .panel > .panel-heading {
  border-radius: 0;
}

.panel-dark {
  border-color: #b5b5b5;
}

.panel-dark > .panel-heading {
  background: #b5b5b5;
}

.panel-dark > .panel-heading > .close {
  line-height: 15px;
  opacity: 0.3;
}

.panel-dark > .panel-heading > .panel-title {
  font-size: 13px;
  color: inherit;
  line-height: 16px;
  letter-spacing: 2px;
  color: #555;
  text-transform: uppercase;
}

.note-panel{

  .media-list{
    margin-bottom: 0;
  }

  padding-bottom: 0;
  .list-group{
    box-shadow: none;
  }
  .list-group-files{
    margin-left: -15px;
    margin-right: -15px;
  }
  .list-group-item{
    border: none;

  }
  .list-group-files .list-group-item .file-icon{
    padding: 7px 10px;
  }
  .list-group-files .list-group-item .file-meta{
    left: 48px;
  }
  .note-trash-btn{
    display: none;
    position: relative;
          right: -3px;
          top: 12px;
  }

  .note-pin-btn{
    position: relative;
          right: -3px;
          top: 12px;
  }
  .note-pin-btn-unpinned{
    display: none;
    position: relative;
          right: -3px;
          top: 12px;
  }

  .note-li{
    margin-left: -15px;
    margin-right: -15px;
  }

  .nav-tabs{
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
  }

  .note-div{
      padding-left: 15px;
      padding-right: 15px;
  }

  .note-li:hover{
    text-decoration: none;
    color: #555;
    background-color: #f5f5f5;
  }

}

.btn-note-save-dropdown{
  padding-left: 5px;
  padding-right: 4px;
}
.note-panel .note-li:hover .note-trash-btn {
    display: inline;
}

.note-panel .note-li:hover .note-pin-btn-unpinned {
    display: inline;
}

.list-group-files.drag-layer {
  .list-group-item {
    background-color: #fff;
  }
}

.list-group-files {
  margin:0;

  .list-group-item.hover {
    text-decoration: none;
    color: #555;
    background-color: #f5f5f5;
  }

  .list-group-item {
    $icon-size: 32px;
    $icon-padding-h: 5px;
    $icon-padding-v: 7px;
    $icon-finalsize-h: $icon-size + 2*$icon-padding-h;
    $icon-finalsize-v: $icon-size + 2*$icon-padding-v;

    background-color: transparent;
    position: relative;
    padding: 0;
    padding-left: $icon-finalsize-h;
    height: $icon-finalsize-v;


    .file-icon {
      position: absolute;
      display: block;

      left: 0;
      top: 0;

      padding: $icon-padding-v $icon-padding-h;
    }

    .file-icon img {
      width: $icon-size;
    }

    .file-meta {
      position: absolute;
      display: block;

      top: 0;
      left: $icon-finalsize-h;
      right: 0;

      padding-top: 5px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-meta .progress {
      margin-right: 10px;
    }

    .file-buttons {
      position: absolute;
      display: block;

      right:0;
      top:0;

      padding-top: 12px;
      padding-right: 12px;
    }

    .label {
      display: inline-block;
      vertical-align: text-bottom;
    }
  }

  .item-container {
    //position: relative;

    .button-container {
      position: absolute;
      right: 9px;
      top: 12px;
      z-index: 1;
      display: none;

      .dropdown-menu {
        margin-top: 4px;
      }
    }
  }

  .item-container:hover .button-container {
    display: block;
  }
}

.list-group-item.read-more, .table td.list-group .list-group-item.read-more {
  padding: 5px;
  height: auto;
  color: #aaa;
  text-align: center;
  vertical-align: middle;
      border-top: 1px solid #ccc;

}

.always-show-remove .list-group.list-group-files .list-group-item:hover .file-buttons{
  .remove{
    display: inline;
  }
  .restore{
    display: inline;
  }
}

.list-group.list-group-files .list-group-item {
  overflow: hidden;

  .file-buttons button {
    display: none;
  }


  .file-buttons button.attach.active {
    display: inline;
  }
}

.edit-mode .list-group.list-group-files .list-group-item {
  .file-buttons button.attach.active {
    /*display: none;*/
  }
}



.edit-mode .list-group.list-group-files .list-group-item:hover {
  .file-buttons button {
    display: inline;
  }
  .file-buttons button.attach {
    /*display: none;*/
  }
}

.list-group.list-group-files .list-group-item:hover {
  .file-buttons button.attach {
    display: inline;
  }
}
