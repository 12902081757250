.file-panel.dragging {

  .list-group-item:hover {
    background-color: transparent;
  }

  .list-group-item.list-group-item-info:hover {
    background-color: #d9edf7;
  }

}

.file-panel {

  .list-group-item.hover {
    background-color: #f5f5f5;
  }

  .list-group-item.list-group-item-info.hover {
    background-color: #c4e3f3;
  }

  .table td:first-child {
    padding-left: 15px;
  }

  .list-group-item:last-child {
    border-bottom: 0;
  }

}


.upload-button {
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  overflow: hidden;

  input[type="file"] {
    position:absolute;
    left:0;
    top:0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  &:hover .btn-default {
    background-color: #e0e0e0;
    background-position: 0 -15px;
    color: #333;
    border-color: #adadad;
  }
}
