.condition-editor {

    .condition {
        .btn-link {
            display: none;
        }
    }
    .condition:hover {
        .btn-link {
            display: inline-block;
        }
    }
    .form-inline {
        &.condition {
            margin-bottom: 5px;
        }

        .form-group {
            vertical-align: top;
        }
        
        .form-control,
        .form-control-static {
            margin-right: 3px;
        }
        margin-right: -3px;
    }
    
}

a.btn-link-danger {
    color: #a94442
}

a.btn-link-danger:hover {
    color: #8d3a3a
}