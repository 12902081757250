          .knowledgebase #settings-content .rendered-content blockquote {
                border: none;
                padding: 0;

                p {
                font-size: 21px;
                font-weight: 300;
                line-height: 1.4;
                }

            }



.knowledgebase-linkrepair-description{
    margin-left: 8px;
    margin-bottom: 20px;
}

.knowledgebase-popover .rendered-content > *:first-child {
    margin-top: 0;
}

          .rendered-content{



              .chapter-title{
                  h2{
                      font-size: 36px;
                  }
              }
              h1{
                  font-size: 30px;
              }
              h2{
                  font-size: 26px;
              }
              h3{
                  font-size: 22px;
              }
              h4{
                  font-size: 18px;
              }
              h5{
                  font-size: 14px;
              }
              h6{
                  font-size: 10px;
              }


              p, ul {
                  font-size: 1.65rem;
                  line-height: 2.65rem;
              }
              img{
                  max-width: 100%;
                  width: auto;
                  height: auto;
              }

              p.lead {
                  font-size: 2rem;
                  line-height: 2.75rem;
              }
          }

.knowledgebase {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;


    .chapter-references div label{
        font-weight: normal;
    }

    .sidebar-add{
        width: 100%;
        display: inline-block;
    }

    .alert-warning{
        margin-bottom: 0px;
    }

    .knowledge-base-chapterlist-chapter{
        button{
            display: none;
        }
    }

    .knowledge-base-chapterlist-chapter.edit{
            button{
                display: initial;
                background: none;
            }
        }

    .sidebar-sort{
        width: 60%;
        display: inline-block;
    }

    #settings-content {

        .heading-dropdown{
            margin-top: 29px;
        }
        .link-dropdown{
            margin-top: 8px;
        }


        .scrollable-dropdown{
            height: auto;
            max-height: 200px;
            overflow-x: hidden;
        }

        .text-input-buttons{
            margin-bottom: 10px;
        }

        padding-top: 40px;


    }

    .sidebar{
        ul li.active ul{
            background: #EAEAEA;
            padding-left: 10px;
            a {
                color: #008FCC;
                box-shadow: none;
                background: none;
                transition: background .4s;
            }
        }
    }

    .sidebar-wrapper {
        z-index: 3;
    }
    .sidebar-add-button {
        z-index: 4;
    }
    .sidebar-search {
        z-index: 4;
    }

    .navigation-top {
        z-index:1;
        position: absolute;
        left: 0;
        right: 240px;
        top: 0;
        text-align: right;
        font-weight: 200;
        padding: 10px 0;
        background: rgba(255,255,255,0.9);
        border-bottom: 1px solid rgba(240,240,240,0.9);
        a {
            color: #999;
        }
        a:hover {
            text-decoration: none;
            color: #333;
        }
    }

    .navigation {
        z-index: 2;
        cursor: pointer;
        color: #ddd;
        text-decoration:none;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        top: 0;
        font-size: 40px;

        &:hover {
            background: rgba(0,0,0,0.05);
            color: #ccc;
        }
        
        &.navigation-prev {    
            left: 0;
            padding-right: 15px;
            padding-left: 20px;
            width: 55px;
        }

        &.navigation-next {    
            right: 240px;
            padding-right: 20px;
            padding-left: 15px;
            width: 55px;
            text-align: right;
        }

        &.navigation-next.has-scrollbar {
            right: 255px;
        }

    }

    
    
}
