$groupBarWidth: 250px;
$sortableHeight: 400px;

.list-group-groups {
  .list-group-item {
    padding-left: 44px;
  }

  .list-group-item.no-indent {
    padding-left: 11px;
  }

  .list-group-item .badge {
    display:block;
    position:absolute;
    left: 10px;
    top: 12px;
  }
}

.list-group-sortable {
  .list-group-item {
    position: relative;
    border-top: 0;
    border-width: 1px 0;
    border-radius: 0;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 11px;
  }

  & > .list-group-item {
    border-top: 1px solid #ddd;
  }
  & > .list-group-item:first-child {
    border-top: 0;
  }

  .list-group-item:hover {
    padding-right: 55px;
  }

  .list-group-item .buttons {
    display: none;
  }

  .list-group-item:hover .buttons {
    display: block;
    position:absolute;
    right: 8px;
    top: 8px;
  }

}


.list-group-sortable-container {
  height: $sortableHeight;
  position: relative;

  .list-group {
    margin-bottom:0;
  }

  .list-group-groups {
    background-color: #f5f5f5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $groupBarWidth+5px;
    overflow: hidden;
    > div {
      height: $sortableHeight;
      width: $groupBarWidth;
      box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
      overflow-y: auto;
    }
    z-index: 2;
  }

  .list-group-items {
    background-color: #f5f5f5;
    position: absolute;
    top: 0;
    bottom:0;
    left: $groupBarWidth;
    right:0;
    z-index: 1;
    overflow-y: auto;
  }
}

.panel-sortable .panel-footer {
  position:relative;
  height: 50px;

  .add-group-container {
    padding: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $groupBarWidth;
  }

  .add-item-container {
    padding: 10px;
    position: absolute;
    top: 0;
    bottom:0;
    left: $groupBarWidth;
    right:0;
  }
}
