.jobs-view {
  table {
    tbody tr td:first-child {
      padding-left: 30px;
    }
  }

}

.jobs-view, .jobs-history {

  table {
    table-layout: fixed;

    .more {
      cursor: pointer;
    }

    .more:hover {
      background: rgb(245,245,245);
    }

    th {
      cursor: pointer;
    }

    th i.fa {
      width: 20px;
      text-align: center;
    }

    .timestamp i.fa {
      width: 15px;
      text-align: center;
    }

    .btn-link {
      text-decoration: none;
    }

    col.timestamp {
      width: 150px;
    }
    col.details {
      width: 205px;
    }

  }


}
