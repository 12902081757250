.table td.list-group {

  padding: 0;

  .list-group-item {
    border-left: 0;
    border-right: 0;
  }

  .list-group-item:first-child, .button-container + .list-group-item {
    border-top: 0;
  }

  .list-group-item:last-child {
    border-bottom: 0;
  }

}
