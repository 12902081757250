

.ui-select-toggle {
  overflow: hidden;
}

.main-container .navigation .profile {
    padding: 2px 0px;

    .btn {
        font-size: 13px;
    }

    .dropdown-menu.adjusted-dropdown {
        margin: 12px 0 0;
    }

}

.navigation .controls {
    .ui-select-container {

        margin-top: 4px;
        margin-right: 4px;

        .dropdown-menu {
            min-width: initial;
        }
    }
}

.main-container .navigation .tabs {
    padding-top: 9px;

    .nav-tabs {
      border-bottom: 0px;
    }

    .nav-tabs li a {
      padding-top: 7px;
      padding-bottom: 7px;
    }
}

.main-container .navigation .buttons {
  padding-top: 6px;
  display: inline-block;
  padding-left: 4px;

  .dropdown-menu.adjusted-dropdown {
      margin: 12px 0 0;
  }
}

.main-container .navigation .ui-select-bootstrap .btn {
   padding: 6px 24px 6px 12px;
}

.dropdown-inline {
  display: inline-block;
  position:relative;
}

.btn-group .btn-clear {
  position: absolute;
  top: 7px;
  right: 8px;
  color: #bbb;
}

.modal-confirm .modal-backdrop {
  z-index: 1060;
}
.modal-confirm .modal {
  z-index: 1070;
}

.jobs-dropdown {

  .btn.btn-lg {
    padding: 8px 12px;
    margin-right: 2px;
  }

  .progress-mini {
    margin: 0;
    height: 4px;
  }

  .dropdown-menu {
    width: 250px;
    padding: 0;

    .list-group {
      margin:0;
    }

    .progress {
      margin: 0px;
    }

    .bottom {
      padding: 10px 15px;
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
    }
  }
}

.list-group-item-container {
  position: relative;
}

.list-group-workflows .list-group-item-container .btn-remove {
    display: none;
    position: absolute;
    right: 9px;
    top: 9px;
    z-index: 1;
}


.list-group-workflows .list-group-item-container:hover .btn-remove {
  display: inline;
}


.jobs-state .panel-body {
  overflow-x: scroll;
}