table.subtasks {
  tr td {
    vertical-align: middle;
  }
  tr td:first-child {
    width: 40px;
    height: 40px;
  }

  tr td:nth-child(2) {
    border-right:0;
  }
  tr td:nth-child(3) {
    border-left:0;
    width:60px;
  }

  .btn-subtask {
    display: none;
  }

  tr.without-file-category:hover td > i {
    display: none;
  }
  tr:hover td .btn-subtask {
    display: inline;
  }
}
