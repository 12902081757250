

.sidebar-wrapper .sidebar {
  position:absolute;
  width: 240px;
  top: 34px;
  right: 0;
  bottom: 0;
  padding-top: 15px;
  overflow-y: auto;
}

.sidebar-wrapper .sidebar.has-add-button {
  bottom: 50px;
}

.template-sidebar-icon{
  width: 14px;
}

#sidebar-container .sidebar-wrapper {
  position:absolute;
  background: #E0E0E0;
  width: 240px;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
  padding-top: 15px;
}

#settings-content {
  position:absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#settings-content.inview-content{
  margin-top: 87px;
  padding-top: 0px;
}

#sidebar-container .content {
  position:absolute;
  top: 0;
  bottom: 0;
  right: 240px;
  left: 0;
}

.sidebar h5 {
  text-transform: uppercase;
  font-size: 12px;
  color: #666;
  letter-spacing: 1px;
  margin-top:6px;
  margin-bottom:6px;
  padding: 0 15px;
}

.sidebar ul {
  list-style-type: none;
  margin:0;
  padding:0;
  line-height: 28px;
}


.sidebar li a i {
  display:inline-block;
  margin-right: 6px;
}

.sidebar li a i.fa-exclamation-circle {
  color:#c00;
}
.sidebar li a i.fa-check-circle {
  color:#0a0;
}

.sidebar li a {
  text-decoration:none;
  padding: 0 15px;
  display:block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar li a {
  cursor: pointer;
}
.sidebar li.active a {
    color: #008FCC;
    box-shadow: inset 3px 0 0 #008FCC;
    background: #F5F5F5;
    transition: background .4s;
}

.group {
  margin-bottom: 20px;
}

.sidebar-add-button {
  position:absolute;
  bottom:0;
  right:0;
  width: 240px;
}
.sidebar-search {
  position:absolute;
  top:0;
  right:0;
  width: 240px;
  padding: 2px;
}

.sidebar-add-button a {
  display:block;
  position:relative;
  height: 50px;
  text-decoration:none;
  background: #E0E0E0;
}

.sidebar-add-button a i {
  display:block;
  position:absolute;
  left:15px;
  bottom: 10px;
  font-size: 200%;
}
.sidebar-add-button a span {
  display:block;
  position:absolute;
  left: 45px;
  bottom: 15px;
}

.sidebar-add-button a:hover {
  background-color:#F5F5F5;
}


#task-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#task-container.has-task-sidebar {
  right: 300px;
}


.task-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    z-index: 2;
    background: white;
    box-shadow: black 1px 1px 4px;
    overflow-y: auto;

    .table {
      margin-bottom:0;
    }

    .task-head {
      position:relative;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 45px;
      padding-right: 15px;

      .label {
        position: absolute;
        top: 7px;
        left: 15px;
      }
    }

    .task-head:hover {
      background: #f9f9f9;
    }


    legend{
        margin-bottom:0;
    }
    .text-bold  .checkbox label {
        font-weight:bold;
    }
    .react-datepicker__input-container{
        width: 100%;
    }


    fieldset {
      .form-group.sidebar-submit {
        margin-bottom: 15px;

        hr {
          margin-top: 0;
          margin-bottom: 15px;
        }
      }

      h2 {
        padding: 8px 15px;
        margin: 0;
        font-size: 14px;
        background: #f9f9f9;
        border-bottom: 1px solid #eee;

        .btn {
          margin-top: -4px;
        }
      }

      .form-group {
        padding: 0 15px;
        margin-top: 15px;
        margin-bottom: 0;
      }
      .form-group.indented {
        padding-left: 35px;
        margin-top: 0;
      }

      legend {
        font-size: 16px;
        background: #eee;
        padding: 10px 15px;
        color: #777;

        .badge {
          float: left;
          margin: 2px 7px 0 0;
        }
      }
    }
}

.has-sidebar .task-sidebar {
  right: 300px;
}
