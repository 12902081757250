.test-component
{
  font-size: 13px;
  $sidebarWidth: 250px;
  $navHeight: 50px;

  .top {
    position: absolute;
    top: 0;
    height: $navHeight;
    left: 0;
    right: 0;
    background: #ccc;
  }

  .content {
    position: absolute;
    top: $navHeight;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content.has-sidebar {
    right: $sidebarWidth;
  }

  .sidebar {
    position: absolute;
    top: $navHeight;
    width: $sidebarWidth;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
  }

}
