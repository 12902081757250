
.mailarchive-browse{
  width: calc(100% - 60px);
}

.mailarchive-browse .columns-container {
    height: calc(100vh - 235px);
    position: relative;
    border-top: 1px solid #e5e5e5;
}

.mailarchive-browse .columns-container .column-folders {
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    width: 200px;
    border-right: 1px solid #e5e5e5;
    overflow: auto;
}
.mailarchive-browse .columns-container .column-list {
    position: absolute;
    left:200px;
    width: 400px;
    top:0;
    bottom:0;
    overflow: auto;
}
.modal-view-modal{
  max-width: 1000px;
  width: auto;
  .modal-body {
    height:600px;
  }  
}


.mailarchive-browse .columns-container .column-mailview {
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    left: 600px;
    border-left: 1px solid #e5e5e5;
    /*overflow: auto;*/
}
.mail-view {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}



.mail-head {
    table-layout: fixed;
    margin: 0;
    flex: 0 0 auto;
}

.mail-frame {
    position:relative;
    flex: 1 1 auto;
    iframe {
        position:absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

.mail-content {
  padding: 15px;
  overflow-x: auto;

  flex: 1 1 auto;
}




.mailarchive-browse-list p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 }
.mailarchive-browse-list .list-group-item {
    border-radius:0;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        background: none;
 }

.column-folders {
    padding: 8px;
}

 .column-folders ul {
         margin-left: 20px;
         padding-left:0;
 }
 .column-folders li {
     position: relative;
     list-style: none;
 }
 .column-folders li a {
    display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }

 .column-folders li a i {
    position: absolute;
    display: block;
    top: 3px;

 }

 .column-folders li a i.fa-caret-right {
    left: -12px;
 }

 .column-folders li a i.fa-spinner {
    left: -18px;
 }

 .column-folders li a i.fa-caret-down {
    left: -14px;
 }

.datepicker__tether-element {
    z-index: 1051;
}

.send-mail .content textarea {
    height: 250px;
}

