.main-sidebar {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
  padding: 2px 0px;
  height: 100%;
  background-color: #E0E0E0;
  box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
  z-index: 500;

  > div {
    top: 0px;
    left: 0px;
    width: 80px;
    bottom: 0px;
    position: absolute;
    
    .menu {
      position: absolute;
      top: 76px;
      bottom: 0;
      width: 80px;
      overflow: hidden;
    }
  }

  .badge {
      position: absolute;
      top: 5px;
      right: 5px;
      background: #d9534f;
  }

  ul li a.bar-item {
      position: relative;
  }

  .scroll-inactive .bottom {
  	position: absolute;
  	bottom: 0;
  	width: 80px;
  }

  .bottom ul li:last-child a {
    padding-bottom: 20px
  }

  
  .brand {
    padding: 18px 17px;
  }
  
  .brand img {
      width: 45px;
  }
  
  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }

  ul li {
    text-align: center;
    background: #E0E0E0;
  }

  ul li a.bar-item i {
  	font-size: 25px;
  	display: block;
  	margin-bottom: 4px;
  	height: 28px;
  }

  ul li.active a.bar-item, ul li.active a.bar-item:hover {
    color: #008FCC;
    box-shadow: inset 3px 0 0 #008FCC;
    background: #F5F5F5;
    transition: background .4s;
  }

  ul li a.bar-item {
    color: #666;
    font-size: 11px;
    display: block;
    line-height: 12px;
    padding: 10px;
    text-decoration: none;
  }

  li a.bar-item:hover {
  	color: #333;
  	text-decoration: none;
  	background: #F5F5F5;
  	transition: background .4s;
  }

  .scroll-up, .scroll-down {
    text-align: center;
    width: 80px;
    position:absolute;
    display:none;
    height: 20px;
    background: rgba(240,240,240,0.8);
    z-index:1;
  }

  .scroll-down {
    bottom: 0;
  }

  ul li a.editlink {
    display: block;
    position: absolute;
    top: 1px;
    right: 1px;
  }
}