
#cardbrowser-content {
  position:absolute;
  top: 0;
  bottom: 0;
  right: 300px;
  left: 0;
  padding: 10px 10px;
  overflow:auto;
}

#cardbrowser-list {
  position:absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  padding: 0;
  overflow: auto;
  h5 {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 0px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      a {
        padding: 5px 10px;
        display: block;
        text-decoration:none;
      }

      a:hover {
        background: #f0f0f0;
      }
    }
  }
}

.dimension-active {
  .panel-card {
    color: #999;
  }
}

.panel-card {
  cursor: pointer;

  .title {
    display       : block;
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
  }

  &.active, &:hover {
    background: #f3f3f3;
    color: #333;
  }

  h1 {
    margin-top: 5px; margin-bottom: 0;
  }
}
