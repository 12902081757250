.grid {
    .panel {
        position:relative;
        
        .compact {
            padding: 4px;
        }

        .panel-heading {
            input[type="text"] {
                background: #f5f5f5;
            }

            .pull-right {
                margin-top: -3px;
                margin-right: -8px;
            }
        }
        

        .panel-scrollable {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            overflow-y: scroll;
            background: #fcfcfc;

            & .list-group {
                margin-bottom:0;

                &.list-group-sm > .list-group-item {
                    padding-top: 6px;
                    padding-bottom: 6px;
                }

                & > .list-group-item {

                    &:first-child {
                        border-top: none;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                    border-radius: 0;
                    border-left: none;
                    border-right: none;
                }
            }
        }
        
        &.has-heading .panel-scrollable {
            top: 38px;
        }
        
        & .has-footer .panel-scrollable {
            bottom: 38px;
        }


        & .panel-footer {
            position:absolute;
            bottom:0;
            left:0;
            right:0;
        }
    }
}

.link-panel-link-delete{
    padding: 0px 5px;
    display: none
}

a.list-group-item.link-panel-link.link-panel-link-active{
    background: #f5f5f5;
}

a.list-group-item.link-panel-link.link-panel-link-active:focus{
    background: #f5f5f5;
}

a.list-group-item.link-panel-link.link-panel-link:hover{
    background: #f5f5f5;
}

a.list-group-item.link-panel-link:focus{
    background: none;
}

.link-panel-link:hover{
    .link-panel-link-delete{
        display: inline-block;
    }
}
