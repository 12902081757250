
.panel.latest-activity > .list-group {
    padding-left: 20px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: visible;

}

/*
Title formatting:
*/
.panel.latest-activity > .list-group .list-group-item {
    border-width: 0;
}

.panel.latest-activity .list-group-item.title {
    position: relative;
    cursor: pointer;
    color: gray;
}

.panel.latest-activity .list-group-item.title .fa.fa-ellipsis-h {
    margin-top: 4px;
}

.panel.latest-activity .list-group-item.title.open,
.panel.latest-activity .list-group-item.title:hover {
    color: inherit;
}

/*
Details formatting:
*/
.panel.latest-activity .list-group-item.details {
    padding: 5px 0 5px 15px;
    height: 30px;
}

.panel.latest-activity .list-group-item.details.note {
    height: initial;
}

.panel.latest-activity .list-group-item.details .aside {
    font-size: 10px;
    position: absolute;
    right: 13px;
    top: 9px;
}

.panel.latest-activity .list-group-item.details.note .aside {
    font-size: 10px;
    right: 13px;
    top: 7px;
    position: initial;
    padding-top: 4px;
    padding-right: 13px;
}

/*
File icon
*/
.panel.latest-activity .list-group-item.details .item-icon {
    position: absolute;
    display: block;
    left: 13px;
    top: 1px;
    padding: 7px 5px;
}

.panel.latest-activity .list-group-item.details .item-description {
    position: absolute;
    display: block;
    left: 35px;
    top: 0;
    padding: 7px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 130px;
}

.panel.latest-activity .list-group-item.details.note .item-description{
    position: initial;
    padding: 3px 24px;
    overflow: initial;
    overflow-x: hidden;
    text-overflow: initial;
    white-space: initial;
}

.panel.latest-activity .list-group-item.details i.fa {
    width: 15px;
    text-align: center;
    display: inline-block;
}



.panel.latest-activity .list-group-item.details  .item-icon img {
    width: 16px;
}
.panel.latest-activity .list-group-item.details  .item-icon i.fa {
    padding-top: 2px;
}

/*
Left side border:
*/
.panel.latest-activity .list-group-item.title::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 19px;
    content: "";
    z-index:0;
}

.panel.latest-activity .list-group-item.title::after {
    position: absolute;
    left: 0;
    top: 19px;
    bottom: 0;
    content: "";
    z-index:0;
}

.panel.latest-activity .list-group-item.title + .list-group-item.title::before, 
.panel.latest-activity .list-group-item.title::after {
    border-left: 2px solid #ddd;
}

.panel.latest-activity .list-group-item.title::before, 
.panel.latest-activity .list-group-item.title:last-child::after {
    border-left: none;
}

.panel.latest-activity .list-group-item.details + .list-group-item.title::before, 
.panel.latest-activity .list-group-item.title.open::after,  
.panel.latest-activity .list-group-item.details {
    border-left: 2px solid gray;
}

/* 
Icon positioning
*/
.panel.latest-activity > .list-group .list-group-item.title i.fa.main-icon {
    position:   absolute;
    top:        12px;
    z-index:    1;
    background: white;
    left:       -5px;
    color:     #ddd;
    font-size:      14px;
}

.panel.latest-activity > .list-group .list-group-item.title.open i.fa.main-icon, 
.panel.latest-activity > .list-group .list-group-item.title:hover i.fa.main-icon {
    color:     gray;
}




