
.columnViewMessage {
    padding: 30px 50px;
    font-weight: bold;
    text-align: right;
    color: #666;
}

.task-columns {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}

.task-columns .column {
    display: inline-block;
    height: 100%;
    width: 275px;
    border-right: 1px solid #eee;
    position:relative;
}

.task-columns .column .column-head {
    background: #fcfcfc;
    padding: 15px 15px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    position: absolute;
    top:0;
    left:0;
    right:0;
    height: 50px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.task-columns .column .column-body .column-item .date-time{
    display: inline-block;
    text-align: center;
    pointer-events: none;
    padding: 0;
    margin-left: -10px;

    .my-i{
        display:inline-block;
        pointer-events: all;
    }


}

.task-columns .column .column-body .column-item:hover .move-to-external{
    visibility: visible;
}
.task-columns .column .column-body .column-item .move-to-external.awaits-external{
    visibility: visible;
    color: #cc8236;
}
.task-columns .column .column-body .column-item .move-to-external{
    i{
        line-height: 18px;
    }
    visibility: hidden;
    vertical-align: top;
    display: inline-block;
}

.task-columns .column .column-body {
    position: absolute;
    top: 50px;
    left:0;
    right:0;
    bottom:0;
    overflow: auto;
}

.task-columns .column .column-body.over{
    background: #f5f5f5;
}

.task-columns .column .column-item.not-started {
    color: #bbb;
}

.task-columns .column .column-item.not-started .label-default {
    background: #bbb;
}

.task-columns .column .column-item.not-started .content-center .secondary {
    color: #bbb;
}
.task-columns .column .column-item.not-started .column-progress .progress-bar {
    background: #bbb;
    box-shadow: none;
}

.task-columns .column .column-item:hover, .task-columns .column .column-item.active {
    background: #fafafa;
    cursor: pointer;
}
.task-columns .column .column-item.active:hover {
    background: #f3f3f3;
    cursor: pointer;
}

.task-columns .column .column-item .column-progress {
    margin: 2px 2px;
    background: #ddd;
    width: 50px;
    height: 2px;
    display: inline-block;
}

.task-columns .column .column-item .column-progress .progress-bar {
    background: #666;
}

.task-columns .column .column-item {
    position: relative;
    height: 54px;
    border-bottom: 1px solid #e0e0e0;
}

.task-columns .column .column-item .content-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 44px;
    padding: 15px 10px;
}

.task-columns .column .column-item .content-left .initials {
    background: #bbb;
    width: 24px;
    height: 24px;
    display: block;
    line-height: 24px;
    text-align: center;
    border-radius: 12px;
    color: white;
    font-size: 10px;
    font-weight: bold;

}

.task-columns .column .column-item .content-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 44px;
    right: 32px;
    padding: 2px 0;
    overflow: hidden;
}

.task-columns .column .column-item-hover{
    background: #f5f5f5;
}

.task-columns .column .column-item-startdrag{
    background: red;
}

.task-columns .column .column-item .content-center .title {
    font-weight:bold;
    display:block;
}
.task-columns .column .column-item .content-center .secondary {
    display:block;
    font-size: 10px;
    color: #777;
}


.task-columns .column .column-item .content-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 55px;
    padding: 17px 0;
}

.task-columns .column .column-item .content-right .fa {

    display: block;
    //text-align: left;
    font-size: 20px;
}
.task-columns .column .column-item .content-right .short-date {
    display: block;
    text-align: center;
    font-size: 8px;
    line-height: 12px;
    font-weight: bold;
}


.task-columns .column .column-item .status.text-success {
    color: #0b0;
}
.task-columns .column .column-item .status.text-muted {
    color: #bbb;
}

.task-columns-bottom-bar {
    position:absolute;
    bottom: -80px;
    left:0;
    right: 0;
    height: 80px;
    background: #f5f5f5;
    box-shadow: 0 0px 5px 3px rgba(0,0,0,0.1);
    padding: 10px;
    transition:all 0.1s ease-in-out;
}

.task-columns-bottom-bar.active {
    bottom:0;
}

.task-columns-bottom-bar .employees {
    float: left;
}

.task-columns-bottom-bar .initials {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #bbb;
    border-radius: 30px;
    color: #fff;
    font-weight: 200;
    font-size: 20px;
    margin-right: 5px;
}

.task-columns-bottom-bar .initials.dragging {
    background: #555;
}

.task-columns-bottom-bar .other-actions {
    float: right;
}

.task-columns-bottom-bar  .other-actions > span {
    display: inline-block;
    width: 120px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    font-weight: 200;
    font-size: 16px;
}

.task-columns-bottom-bar .other-actions > span.remove {
    background: rgb(167, 72, 72);
}

.task-columns-bottom-bar .other-actions > span.complete {
    background: rgb(72, 167, 72);
}

.task-columns-bottom-bar .other-actions > span.remove.dragging {
    background: rgb(230, 0, 0);
}

.task-columns-bottom-bar .other-actions > span.complete.dragging {
    background: rgb(0, 230, 0);
}

.task-columns-notifications{
    position: absolute;
    bottom: 10px;

    transition:all 0.1s ease-in-out;

    &.bottom-bar-is-active {
        bottom: 90px;
    }

    left: 20px;
    right: 20px;
}

.column-view-dropdown{
    margin: 9px 0 0;
}

.column-view-dropdown > li > a{
    cursor: pointer;
}

.task-columns-notifications .alert{
    background: rgba(240,240,240,0.9);
    border: 1px solid #ccc;
    margin-bottom: 5px;
    padding: 10px;
    padding-top: 11px;
    color: #666;
}
.task-columns-notifications button{
    background: #eee;
    border: 1px solid #ccc;
    color: #666;

}
.task-columns-notifications .buttons-container{
    margin-top: -6px;
    margin-right: -5px;
}
