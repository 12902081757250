$sidebarWidth : 300px;

#settings-content {

    position:absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    overflow:auto;
    font-size: 13px;

    .content-head {
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    .content-head h5 {
        text-transform: uppercase;
        font-size: 12px;
        color: #666;
        letter-spacing: 1px;
        margin: 3px 0 0 0;
    }

    .content-head h2 {
        margin: 8px 0 0 0;
        font-weight: 200;
    }

    .content-head .btn {
        margin: 10px 0 0 0;
    }

    .avatar {
        background: #ccc;
        width: 100px;
        height: 100px;
        float: left;
        margin-right: 12px;
    }

    table.table {
        font-size: 13px;
    }

}

.content .has-sidebar #settings-content {
    right: $sidebarWidth;
}

.content .sidebar {

  position: absolute;
  top: 0;
  width: $sidebarWidth;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
}
