.list-group .list-group-item.active .text-muted {
color: #fff;
}
.user-settings h3 {
margin-top: 0;
font-weight: 200;
}
.user-settings h4 {
font-weight: 200;
}
.user-settings h5 {
font-weight: 200;
}