.main-logon {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #f1f1f1;
    overflow: auto;

    .brand img {
        width: 291px;
    }

    form#login-form {
      margin:0;
    }

    .login-container {
      margin: 150px 0 50px 0;
      
      .footer {
        margin-top: 10px;
      }
    }

    .login-container h3 {
    	text-align:center;
    }

    .login-box {
    	border: 1px solid #cfcfcf;
    	border-radius: 3px;
    	width: 350px;
    	margin: 0 auto;
    	background: white;
    	padding-top: 20px;
    	padding-left: 20px;
    	padding-right: 20px;
    	padding-bottom: 5px;
    	margin-top: 20px;
    }

    .slide-alert {
      overflow: hidden;
    }

    .slide-alert-enter {
      max-height : 0;
      transition : all .2s ease-in;
    }

    .slide-alert-enter.slide-alert-enter-active {
      max-height : 150px;
    }

    .slide-alert-leave {
      max-height : 150px;
      transition : all .2s ease-out;
    }

    .slide-alert-leave.slide-alert-leave-active {
      max-height : 0;
    }


    .slide-field {
      overflow: hidden;
    }

    .slide-field-enter {
      max-height : 0;
      transition : all .2s ease-in;
    }

    .slide-field-enter.slide-field-enter-active {
      max-height : 70px;
    }

    .slide-field-leave {
      max-height : 70px;
      transition : all .2s ease-out;
    }

    .slide-field-leave.slide-field-leave-active {
      max-height : 0;
    }

}

