@import "filepanel.scss";
@import "testcomponent.scss";
@import "table-list-group.scss";
@import "list-group-files.scss";
@import "list-group-sortable.scss";
@import "mail.scss";
@import "subtasks.scss";
@import "sidebar.scss";
@import "cardbrowser.scss";
@import "latest-activity.scss";
@import "grid.scss";
@import "knowledgebase.scss";
@import "columnview.scss";
@import "condition-editor.scss";

textarea.form-control {
  min-width: 100%;
  max-width: 100%;
}
.popover-title {
  dl {
    font-size: 12px;
    margin-bottom:0;
    dd {
      white-space   : nowrap;
      overflow      : hidden;
      text-overflow : ellipsis;
    }
  }
}

.list-group-item.hovering{
  background: #eee;
}


.knowledgebase-popover{
  width: 600px;
  max-width: 600px;
  .rendered-content{
    padding: 20px;

  }
}



.content-head.clearfix.inview{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: white;
  z-index: 2;
  padding: 20px;
  padding-bottom: 10px;
  height: 85px;

  h5{
    text-transform: uppercase;
    font-size: 12px;
    color: #666;
    letter-spacing: 1px;
    margin: 3px 0 0 0;
  }

  h2{
    margin: 8px 0 0 0;
    font-weight: 200;
  }

}

.has-sidebar .content-head.clearfix.inview{
  right: 300px;
}

.knowledgebase-chapter-edit-button{
  position: absolute;
  top:3px;
  right:25px;
}
.knowledgebase-chapter-del-button{
  position: absolute;
  top:3px;
  right:2px;
}
.file-sidebar .panel{
  margin: 0px;
}

.nav-tabs>li>a {
  padding: 5px 8px;
}

.panel .panel-heading {
  div.pull-right {
    margin-right: -6px;
    margin-top: -2px;
  }

  .dropdown-menu {
    margin-top: 0px;
  }
}

.text-strikethrough {
  text-decoration: line-through;
}

.modal .panel {
  margin-bottom: 0;
}

.file-sidebar .list-group {
    overflow-y: scroll
}

.new .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}


.tablegroup {
    font-weight: bold;
    background: #eee;
}


.well h3 {
  font-size: 16px;
  margin-top:0;
  padding-bottom: 5px;
  border-bottom: 1px dotted #aaa
}



.confirm-container {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    text-align: center;
    z-index:100;
}

.confirm-container .alert {
    display: inline-block;
    padding: 5px 9px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-size: 11px;
    opacity: 0.9;
}

.confirm-container .alert:hover {
  opacity: 0.5;
}

.fcg-modal .btn{
  overflow: hidden;
}

.gdpr-setting-row {
  padding: 4px 0;
  line-height: 2.5;
}

.gdpr-setting-fieldset {
  margin-bottom: 20px;
}

.gdpr-row-delete {
  display: none;
}

.gdpr-setting-row:hover .gdpr-row-delete {
  display: block;
}

.gdpr-add-button {
  margin-top: 4px;
  margin-bottom: 4px;
}

.gdpr-checkbox-cell{
  font-size: 20px;
  text-align: center;
}

.mail-template-fields.dl-horizontal {
  dt {
    width: 250px;
  }

  dd {
    margin-left: 270px;
  }

}