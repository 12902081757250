.main-container {
  position: absolute;
  top: 0px;
  left: 80px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;

  > .navigation {
    > .top {
        background: #efefef;
        padding: 0px 20px;
        min-height: 45px;
    }

    > .bottom {
        background: #f8f8f8;
        border-bottom: none;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        min-height: 43px;
        box-shadow: 0 8px 4px -3px rgba(0,0,0,0.15);
        position: relative;
        z-index: 5;
    }
  }

  > .content {
    position:absolute;
    top: 88px;
    left:0;
    bottom:0;
    right:0;
  }
}
