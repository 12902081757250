.googlemap {
  position: absolute;
  left: 0;
  right:0;
  top:0;
  bottom: 0;
}

.query-chart .dropdown {
  display: block;
}

.query-state .dropdown {
  position: initial;
}

.navbar-query-editor .ui-select-container {
    width:200px;
}
.navbar-query-editor .navbar-form .form-control {
    width: 100%;
}

.query-chart .query textarea {
    height: 200px;
}
.query-chart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.query-chart .bottom-menu {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 55px;
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
}
.query-chart .bottom-menu.open {
    height: 200px;

}

.query-chart .query-builder {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
}


.query-chart .query-builder.bottom-menu-closed {
    bottom: 55px;
}

.query-chart .query-builder.bottom-menu-open {
    bottom: 200px;
}

.query-chart .query-preview {
    position:absolute;
    right:0;
    top: 0;
    bottom: 0;
    left: 0;
}

.query-chart.edit-mode .query-preview {
    right: 300px;
}

.query-chart .side-menu .heading {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.query-chart .side-menu .heading:hover {
    text-decoration: none;
}

.query-chart .side-menu .heading.primary {
    position: absolute;
    top:0;
    left:0;
    right:0;
    background: #a9a9a9;
    height: 55px;
    color: #fff;
    z-index: 101;
}

.query-chart .side-menu .dropdown-primary {
    height: 50px;
    display: block;
}

.query-chart .side-menu .heading.primary:hover {
    background: #a0a0a0;
}

.query-chart .side-menu .heading.secondary {
    background: #f8f8f8;
    border-bottom: 1px solid #e8e8e8;
}

.query-chart .side-menu .heading .main-heading {
    display: block;
}

.query-chart .side-menu .heading.primary .main-heading {
    /*color: #666;*/
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
}

.query-chart .side-menu .heading.secondary .main-heading {
    color: #333;
    font-size: 12px;
    font-weight: 300;
    margin-right: 20px;
}

.query-chart .side-menu .heading.secondary .main-heading i.fa {
    width: 16px;
    text-align: right;
    padding-right: 3px;

}

.query-chart .side-menu .heading .sub-heading {
    display: block;
    margin-top: 4px;
    font-size: 10px;
    color: #e8e8e8/*rgb(76, 154, 187)*/;
}

.query-chart .side-menu .heading .icon {
    position: absolute;
}

.query-chart .side-menu .heading.primary .icon {
    font-size: 19px;
    top: 17px;
    right: 15px;
}

.query-chart .side-menu .heading.secondary .icon {
   font-size: 12px;
   top: 12px;
   color: #999;
   right: 15px;
}

.dropdown-menu.dropup {
    bottom: 100%;
    top: auto;
}


/*.query-chart .query-builder .heading.secondary.multiple-hierarchies .icon {
   top: 22px;
}*/

.query-chart .side-menu .heading.secondary.active {
    background: #e8e8e8;
}


.query-chart .side-menu .heading .label.label-info {
    font-weight: 200;
    display: inline-block;
    margin-top: 4px;
    margin-left: 13px;
    background-color: transparent;
    color: rgb(76, 154, 187);
}
.query-chart .side-menu .heading .label.label-info .fa {
    visibility:hidden;
}

.query-chart .side-menu .heading .label.label-info:hover {
    background-color: #5bc0de;
    color: #fff;
}

.query-chart .side-menu .heading .label.label-info:hover .fa {
    visibility:visible;
}

.query-chart .side-menu .element {
    position: relative;
    display: block;
    padding: 4px 16px 4px 34px;
    font-size: 10px;
    font-weight: 200;
    color: #333;
}

.query-chart .side-menu .bottom-menu-element {
    font-size: 12px;
}

.query-chart .side-menu .element:first-child {
    padding-top: 8px;
}

.query-chart .side-menu .element:last-child {
    padding-bottom: 8px;
}

.query-chart .side-menu .element:hover {
    background: #f8f8f8;
    text-decoration:none;

}

.query-chart .side-menu .content-wrapper {
    overflow: auto;
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    z-index: 100;
}

.query-chart .query-builder .content-wrapper {
    top: 55px;
}


.query-state .head {
    text-align: right;
    font-weight: bold;
    width: 100px;
}
.query-state .head .fa{
    padding-left: 10px;
}

.query-state td {
    height: 47px;
}

.query-state {
    position: relative;
}

.query-state .swap {
    position: absolute;
    top: 86px;
    left: 122px;
}

.query-state .head {
    text-align: right;
    font-weight: bold;
    width: 155px;
    padding-right: 50px;
    vertical-align: middle;
}

.query-state td {
    position:relative;
}

.query-state .head .fa {
    position: absolute;
    top: 16px;
    left: 4px;
}

.query-chart .error-container {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
}

.query-chart .alert {
        font-size: 12px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 10px;
        display:inline-block;
}



.chart-type-btn-container {
    position: relative;
    width: 100px;
}

.query-state table.table {
    border-collapse: initial;
}


.chart-type-btn {
    display:block;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    width:100px;
    border-radius: 0;
}

.chart-type-icon {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 34px;
    text-align: center;
    font-size: 40px;
}

.chart-type-description{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 4px;
    font-size: 11px;
    padding-top: 10px;
}

.dashboard-add-row-description {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 16px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 4px;
    font-size: 11px;
    padding-top: 10px;
}

.query-state {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    z-index: 2;
}

.chart-type-dropdown-menu {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    border-radius: 0;
    margin-top: 0px;
    padding:0;
}
.chart-type-dropdown-menu li {
    text-align: center;
    font-size: 40px;
}
.chart-type-dropdown-menu li a {
    color: #555;
    padding: 10px 0;
}
.chart-type-dropdown-menu li:first-child a {
    color: #555;
    padding-top: 16px;
}
.chart-type-dropdown-menu li:last-child a {
    color: #555;
    padding-bottom: 16px;
}
.query-chart  .query-preview .chart-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.top-buffer { margin-top:20px; }

.query-chart.edit-mode .query-preview  .chart-container {
    top: 142px;
}


.query-chart .dashboard-container {
    position: absolute;
    overflow: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.query-chart.edit-mode .dashboard-container .row {
    padding-left: 40px;
    position: relative;
}

.query-chart.edit-mode .dashboard-container {
    top: 47px;
}

.query-chart .dashboard-container .row {
    position: relative;
}

.query-chart .dashboard-container .row .badge.delete-row {
    height: 22px;
    left: 20px;
    position: absolute;
    top: 10px;
    border-radius: 11px;
    padding-top: 5px;
    background: transparent;
    padding-left: 6px;
    padding-right: 6px;
}


.query-chart .dashboard-container .row:hover .badge.delete-row  {
    background: #999;
}

.query-chart .dashboard-container .row .badge.delete-row:hover {
    background: #800;
}

.query-chart .dashboard-container .row .panel.is-empty .dropdown-menu {
    top: 31px;
    right: 23px;
}

.query-chart .dashboard-container .row .panel.is-empty {
    border-style: dashed;
}

.textarea-200px textarea {
    height: 200px;
}

/*
StringRep Editor:
*/
.stringrep-element
{
    background: #F1F1F1;
    border: 1px solid #ccc;
    border-left-width: 3px;
    padding: 7px;
    padding-left: 25px;
    position:relative;
}

.stringrep-element .btn-close {
    position: absolute;
    left: 4px;
    top: 6px;
}

.stringrep-element .title{
    display:inline-block;
    color: #777;
    font-size: 13px;
    margin-top:0;
    margin-bottom:7px;
}


.stringrep-element.has-error {
    border-color: #C1A1A1;
}

.stringrep-element.has-error .title {
    color: #800101;
}

.stringrep-element.no-close {
    padding-left: 7px;
}

.panel-body.google-map-container  {
    padding:0;
    width:100%;
    padding-bottom: 75%;
    position:relative;
}

.panel-body.google-map-container .google-map {
    position:absolute;
    top: 0;
    left:0;
    right:0;
    bottom:0;
}

.dashboard-gallery {
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-bottom: 75%;
}

.dashboard-gallery img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
}

.dashboard-gallery .prev-image,.dashboard-gallery .next-image {
    position: absolute;
    display: block;
    top: 0px; bottom: 0px;
}

.dashboard-gallery .prev-image {
    padding-top: 10px;
    left: 10px;
}
.dashboard-gallery .next-image {
    padding-top: 10px;
    right: 10px;
}

.note-modal {
  width: 80%;

  .modal-body {
    height: 400px;
  }
}

.transparent {
  opacity: 0.5;
}



.panel-body.dashboard-text {
    padding: 10px 10px;
    line-height: 22px;
}
.panel-body.dashboard-text + .panel-body.dashboard-text {
    border-top: 1px solid #ccc;
}

.table.dashboard-text tr td {
    padding: 4px 10px;
    font-size: 12px;
    line-height: 20px;
    border: none;
}

.table.dashboard-text tr:first-child td {
    padding-top: 10px;
}
.table.dashboard-text tr:last-child td {
    padding-bottom: 10px;
}

.dashboard-container  {
    table.cernotable tr td:first-child, table.cernotable tr th:first-child {
        padding-left: 10px;
    }
}
