body {
  font-family: 'Lato', sans-serif;
}

@import "logon.scss";
@import "sidebar.scss";
@import "container.scss";
@import "app.scss";
@import "settings-content.scss";
@import "user-settings.scss";
@import "table.scss";
@import "jobs-view.scss";
