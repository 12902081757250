
#main-content, .note-modal {

    .table-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

  .truncate {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .nontablebody {
    overflow: auto;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0px;
    right: 0;
    padding: 20px;
  }

  $secondary-filters-width: 250px;

  .nontablebody.has-secondary-filters {
    left: $secondary-filters-width;
  }

  .secondary-filters.has-secondary-filters {
    position:absolute;
    background: #eee;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: $secondary-filters-width;
    border-bottom: 2px solid #ccc;
    overflow-y: scroll;
    padding: 5px 10px;


    .container-fluid {
      padding-left: 20px
    }

    h5 {
      font-size: 10px;
      text-transform: uppercase;
      color: #777;
      margin: 11px 0 6px 9px;
      font-weight: bold;
    }

    .list-group-item {
      cursor: pointer;
      padding: 4px 8px;
      font-size: 11px;
      line-height: normal;
      background: #f5f5f5;
      position:relative;

      .badge {
        position:absolute;
        right:5px;
        top:4px;
        font-size: 10px;
      }


      &.level-0 {
        margin-top: 10px;
        padding: 10px 0px 4px 0px;
        font-weight: bold;
        font-size: 13px;

        .badge {
          top: 8px;
        }

      }


      .truncate {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }


    .list-group-item:hover {
      background: #e0e0e0;
    }

    .list-group-item.selected {
      background: #cfcfcf;
    }

  }

  .has-sidebar .secondaryFilters {
    right: 300px;
  }


  .has-sidebar .nontablebody {
    right: 300px;
  }

  .tablehead {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 17px; /* scrollbar -- is later finetuned by JS using measurement */
    overflow:hidden;
    z-index:1;
    background: white;
    /*pointer-events: none;*/
  }

  .tabletitle {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 17px; /* scrollbar -- is later finetuned by JS using measurement */
    overflow:hidden;
    z-index:1;
    background: white;
    /*pointer-events: none;*/
  }

  .tablecorner {
    position: absolute;
    top: 0px;
    left: 0px;
    overflow:hidden;
    z-index:2;
    background: white;
    /*pointer-events: none;*/
  }

  .tablebody {
    overflow-y: auto;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0px;
    right: 0;
    /*padding-top:90px;*/
  }


  .tablehead table,.tablecorner table {
    margin-bottom: 0;
  }

  .tablebody table,.tablehead table,.tabletitle table,.tablecorner table {
    border-left: 20px solid #fff;
  }


}

/* cernotable */


table.cernotable {
  .headline .num {
    color: rgba(0,0,0,0);
  }
}

table.cernotable>tbody>tr>td,
table.cernotable>thead>tr>th {
  padding: 4px 10px;
  height: 20px;
  line-height: 20px;
  border-top: none;
  border-bottom: none;
}

table.cernotable>thead>tr>th {
	padding-top: 10px;
  padding-bottom: 10px;
}
table.cernotable>thead>tr:nth-child(1)>th {
	padding-top: 20px;
}
table.cernotable.gray-top>thead>tr:nth-child(1)>th {
	background-color: #E0E0E0;
}

table.cernotable>thead>tr:nth-child(2)>th {
	background-color: #E0E0E0;
}


table.cernotable tr.odd {
	background-color: #F2F2F2;
}

table.cernotable>thead>tr th.space {
	background-color:transparent !important;
}

table.cernotable tr td:first-child, table.cernotable tr th:first-child {
  padding-left: 20px;
}

table.cernotable .btn-xs {
  height: 20px;
  padding: 0px 9px;
}

table.cernotable>tbody>tr>td.fill, table.cernotable>thead>tr>th.fill {
  background: #fff !important;
  width: 100%;
}


table.cernotable {
  table-layout: fixed;
  width: auto;
  font-size: 12px;
  color: #404040;
}
.dashboard-container table.cernotable {
  min-width: 100%;
  max-width: 100%;
}
table.cernotable.darklinks a {
  color: #404040;
}
table.cernotable.darklinks a:hover {
  color: #000000;
}

/*
Header har fixed højde
så derfor NO OVERFLOW!
*/
table.cernotable th,table.cernotable td.text {
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/*
COLUMN STYLES
*/

table.cernotable .nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
table.cernotable .widetext {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
table.cernotable .text {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}
.dashboard-container table.cernotable .text {
  width: auto;
  min-width: initial;
  max-width: 0;
}
table.cernotable .mediumtext {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}
table.cernotable .narrowmediumtext {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}
table.cernotable .narrowtext {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}
table.cernotable .verynarrowtext {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
}
table.cernotable .veryverynarrowtext {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}
table.cernotable .singlecharacter {
  width: 35px;
  min-width: 35px;
  max-width: 35px;
}
table.cernotable .note {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  text-align:center;
}
table.cernotable .btn-small {
  height: 20px;
  padding: 0px 9px;
}
table.cernotable .space {
  width: 15px;
  min-width: 15px;
  max-width: 15px;
  padding:0;
}
table.cernotable tr td.space, .table-striped > tbody > tr > td.space {
  background: white;
}

table.cernotable .center {
  width: 85px;
  min-width: 85px;
  max-width: 85px;
  text-align: center;
}
table.cernotable .num, table.cernotable .numwide {
  width: 85px;
  min-width: 85px;
  max-width: 85px;
  text-align: right;
}

table.cernotable .colspan {
  text-align:center;
}

table.cernotable th.rowspan {
  white-space: normal;
  vertical-align:top;
  text-align:center;
  padding-left: 0;
  padding-right: 0;
}

table.cernotable .num.small, table.cernotable .numwide.small {
  width: 65px;
  min-width: 65px;
  max-width: 65px;
}


table.cernotable .num.wide, table.cernotable .numwide.wide {
  width: 105px;
  min-width: 105px;
  max-width: 105px;
}


table.cernotable .num.narrow, table.cernotable .numwide.narrow {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  padding-left: 0;
}



table.cernotable td.diff.pos {
	color: #080;
}

table.cernotable td.diff.neg {
	color: #E00;
}


/*
ROW STYLES
*/

table.cernotable tr.bold, table.cernotable td.bold {
	font-weight: bold;
}

table.cernotable tr.italic {
	font-style: italic;
}
table.cernotable tr td.italic {
	font-style: italic;
}
table.cernotable tr td.not-italic {
	font-style: normal;
}

table.cernotable tr.underline td.num,table.cernotable tr.underline td.numwide, table.cernotable tr td.underline {
	border-bottom: 1px solid #CFCFCF;
}

table.cernotable tr.dunderline td.num,table.cernotable tr.dunderline td.numwide, table.cernotable tr td.dunderline  {
	border-bottom: 3px double #CFCFCF;
}

table.cernotable tr.overline td.num,table.cernotable tr.overline td.numwide, table.cernotable tr td.overline {
	border-top: 1px solid #CFCFCF;
}
table.cernotable tr.overline-heavy td.num,table.cernotable tr.overline-heavy td.numwide, table.cernotable tr td.overline-heavy {
	border-top: 2px solid #A0A0A0;
}


table.cernotable td.bg-gray {
  background: #E0E0E0;
}


.label.label-hlm-hlm, option.bg-hlm-hlm, table.cernotable td.bg-hlm-hlm {
  background: #1aaf54;
}
.label.label-hlm-kursus, option.bg-hlm-kursus, table.cernotable td.bg-hlm-kursus {
  background: #fdbf2d;
}
.label.label-hlm-oplaering, option.bg-hlm-oplaering, table.cernotable td.bg-hlm-oplaering {
  background: #fffd38;
}
.label.label-hlm-oplaering {
  color: #444;
}
.label.label-hlm-ferie, option.bg-hlm-ferie, table.cernotable td.bg-hlm-ferie {
  background: #5183bb;
}
.label.label-hlm-sygdom, option.bg-hlm-sygdom, table.cernotable td.bg-hlm-sygdom {
  background: #fc0d1b;
}
.label.label-hlm-afspadsering, option.bg-hlm-afspadsering, table.cernotable td.bg-hlm-afspadsering {
  background: #c4bd99;
}
.label.label-hlm-barsel, option.bg-hlm-barsel, table.cernotable td.bg-hlm-barsel {
  background: #7f65a1;
}


table.cernotable>thead>tr:nth-child(2)>th.headbg-hlm-hlm, table.cernotable tr.headbg>td.headbg-hlm-hlm {
  background: #1aaf54;
}
table.cernotable>thead>tr:nth-child(2)>th.headbg-hlm-kursus, table.cernotable tr.headbg>td.headbg-hlm-kursus {
  background: #fdbf2d;
}
table.cernotable>thead>tr:nth-child(2)>th.headbg-hlm-oplaering, table.cernotable tr.headbg>td.headbg-hlm-oplaering {
  background: #fffd38;
}
table.cernotable>thead>tr:nth-child(2)>th.headbg-hlm-ferie, table.cernotable tr.headbg>td.headbg-hlm-ferie {
  background: #5183bb;
}
table.cernotable>thead>tr:nth-child(2)>th.headbg-hlm-sygdom, table.cernotable tr.headbg>td.headbg-hlm-sygdom {
  background: #fc0d1b;
}
table.cernotable>thead>tr:nth-child(2)>th.headbg-hlm-afspadsering, table.cernotable tr.headbg>td.headbg-hlm-afspadsering {
  background: #c4bd99;
}
table.cernotable>thead>tr:nth-child(2)>th.headbg-hlm-barsel, table.cernotable tr.headbg>td.headbg-hlm-barsel {
  background: #7f65a1;
}



.table tr.bg-salmon:nth-of-type(odd), .table.cernotable thead tr:nth-child(2)  th.bg-salmon {
	background-color: #fbd5b6;
}
.table tr.bg-blue:nth-of-type(odd), .table.cernotable thead tr:nth-child(2)  th.bg-blue {
	background-color: #b9cce3;
}
.table tr.bg-lilac:nth-of-type(odd), .table.cernotable thead tr:nth-child(2)  th.bg-lilac {
	background-color: #ccc1d9;
}
.table tr.bg-green:nth-of-type(odd), .table.cernotable thead tr:nth-child(2)  th.bg-green {
	background-color: #d8e3be;
}
